import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checked) {
      navigate('/');
    } else {
      alert('You must agree to the privacy policy to continue.');
    }
  };

  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <div className="privacy-policy-content">
        <h2>Effective Date: 09/10/2024</h2>
        <p>
          At <b>Sunrise Dairy</b>, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our <b>Sunrise Dairy Delivery Management System</b> ("Software").
        </p>
        
        <h3>1. Information We Collect</h3>
        <p>We may collect the following information:</p>
        <ul className="info-list">
          <li><b>Personal Identification Information</b>: Name, address, phone number, email address.</li>
          <li><b>Delivery Data</b>: Delivery records, invoicing details, and product quantities.</li>
          <li><b>QuickBooks Data</b>: When using the Software to connect with QuickBooks, we collect and store information necessary to create invoices, including customer and product data.</li>
          <li><b>Usage Information</b>: Information about how you interact with the Software, including access times, IP address, and browser type.</li>
        </ul>

        <h3>2. How We Use Your Information</h3>
        <p>We use the information we collect for:</p>
        <ul className="usage-list">
          <li>Managing deliveries and generating invoices through the Software.</li>
          <li>Connecting with your QuickBooks account to facilitate invoicing.</li>
          <li>Providing customer support and responding to inquiries.</li>
          <li>Improving and optimizing the Software’s functionality and user experience.</li>
          <li>Complying with legal obligations, enforcing our terms and conditions, and protecting the security of the Software.</li>
        </ul>

        <h3>3. How We Share Your Information</h3>
        <p>We may share your information in the following ways:</p>
        <ul className="sharing-list">
          <li><b>With QuickBooks</b>: The Software integrates with QuickBooks to generate invoices. Relevant data such as customer and product information may be shared with QuickBooks for this purpose.</li>
          <li><b>Service Providers</b>: We may share your data with third-party service providers to perform services on our behalf, such as cloud hosting or analytics providers.</li>
          <li><b>Legal Obligations</b>: If required by law, we may disclose your information to comply with legal proceedings or requests from government authorities.</li>
        </ul>

        <h3>4. Data Retention and Security</h3>
        <p>
          We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction.
        </p>

        <h3>5. Your Data Protection Rights</h3>
        <p>Depending on your location, you may have the following rights regarding your personal data:</p>
        <ul className="rights-list">
          <li><b>Access</b>: You have the right to request copies of your personal data.</li>
          <li><b>Rectification</b>: You have the right to request corrections to inaccurate or incomplete personal data.</li>
          <li><b>Erasure</b>: You have the right to request the deletion of your personal data, under certain conditions.</li>
          <li><b>Objection</b>: You have the right to object to our processing of your personal data, under certain conditions.</li>
        </ul>

        <h3>6. Third-Party Links</h3>
        <p>
          The Software may contain links to third-party websites or services that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
        </p>

        <h3>7. Children's Privacy</h3>
        <p>
          Our Software is not intended for use by children under the age of 13. We do not knowingly collect personal data from children under 13. If you become aware that a child has provided us with personal data, please contact us so we can take appropriate action.
        </p>

        <h3>8. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting of the revised Privacy Policy. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
        </p>

        <h3>9. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
          <br />
          <b>Sunrise Dairy</b><br />
          574 Bud Tanner Rd, Crossville, TN 38571, United States<br />
          sales@sunrisedairytn.com<br />
          931-277-3777
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <label>
          <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
          I have read and agree to the Privacy Policy
        </label>
        <br />
        <button type="submit" disabled={!checked}>
          Agree and Continue
        </button>
      </form>
    </div>
  );
};

export default PrivacyPolicy;
