import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Stores from './components/Stores';
import Products from './components/Products';
import AssignProductPage from './components/AssignProductPage';
import DeliveryList from './components/DeliveryList';
import EditDelivery from './components/EditDelivery';
import Login from './components/Login';
import QuickBooks from './components/QuickBooks';
import EndUserLicenseAgreement from './components/EndUserLicenseAgreement';
import PrivacyPolicy from './components/PrivacyPolicy';
import PrivateRoute from './components/PrivateRoute'; // Import PrivateRoute
import authService from './services/authService'; // Import authService
import './styles.css';  // Import the styles.css file
import logo from './logo.png'; // Import the logo image

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('access_token'));

  const handleLogout = () => {
    authService.logout();
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div className="App">
        <header className="bg-blue-600 text-white py-2 mb-4 shadow-md">
          <div className="container mx-auto flex justify-between items-center">
            <img src={logo} alt="Sunrise Dairy Logo" className="h-10 w-10 mr-2" />
            <h1 className="text-2xl font-bold">Sunrise Dairy Delivery Management System</h1>
            {isAuthenticated && (
              <nav className="navbar-container space-x-4 flex items-center">
                <Link to="/deliveries" className="nav-link">Deliveries</Link>
                <div className="navbar relative">
                  <span className="nav-link cursor-pointer">Settings</span>
                  <div className="dropdown-content absolute hidden bg-white text-blue-600 p-2 rounded shadow-md">
                    <Link to="/assign" className="nav-link block p-1 hover:bg-blue-600 hover:text-white rounded">Assign Products</Link>
                    <Link to="/stores" className="nav-link block p-1 hover:bg-blue-600 hover:text-white rounded">Stores</Link>
                    <Link to="/products" className="nav-link block p-1 hover:bg-blue-600 hover:text-white rounded">Products</Link>
                    <Link to="/quickbooks" className="nav-link block p-1 hover:bg-blue-600 hover:text-white rounded">QuickBooks</Link>
                  </div>
                </div>
                <button onClick={handleLogout} className="nav-link bg-red-500 p-2 rounded hover:bg-red-700 text-white">Logout</button>
              </nav>
            )}
          </div>
        </header>
        <div className="container mx-auto p-4">
          <Routes>
            <Route path="/" element={<PrivateRoute><DeliveryList /></PrivateRoute>} />
            <Route path="/login" element={<Login onLogin={() => setIsAuthenticated(true)} />} />
            <Route path="/" element={<PrivateRoute><DeliveryList /></PrivateRoute>} />
            <Route path="/assign" element={<PrivateRoute><AssignProductPage /></PrivateRoute>} />
            <Route path="/deliveries" element={<PrivateRoute><DeliveryList /></PrivateRoute>} />
            <Route path="/edit-delivery/:deliveryId" element={<PrivateRoute><EditDelivery /></PrivateRoute>} />
            <Route path="/quickbooks" element={<PrivateRoute><QuickBooks /></PrivateRoute>} />
            <Route path="/stores" element={<PrivateRoute><Stores /></PrivateRoute>} />
            <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />
            <Route path="/agreement" element={<EndUserLicenseAgreement />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
