import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { startOfWeek, endOfWeek } from 'date-fns';
import api from '../services/api';  // Import the configured Axios instance
import '../styles.css';  // Import the styles.css file
import memoize from 'lodash/memoize';

Modal.setAppElement('#root'); // This is important for accessibility

const memoizedFormatDate = (() => {
  const cache = {};
  return (date) => {
    const key = date.toISOString();
    if (!cache[key]) {
      const d = new Date(date);
      const day = String(d.getUTCDate()).padStart(2, '0');
      const month = String(d.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = String(d.getUTCFullYear()).slice(-2); // Get last two digits of the year
      cache[key] = `${month}/${day}/${year}`;
    }
    return cache[key];
  };
})();

const groupByWeek = memoize((deliveries) => {
  const grouped = {};

  deliveries.forEach(delivery => {
    const weekStart = startOfWeek(new Date(delivery.date), { weekStartsOn: 0 });
    const weekEnd = endOfWeek(new Date(delivery.date), { weekStartsOn: 0 });
    const key = `${memoizedFormatDate(weekStart)} to ${memoizedFormatDate(weekEnd)}`;

    if (!grouped[key]) {
      grouped[key] = [];
    }

    grouped[key].push(delivery);
  });

  return grouped;
});

const formatWeekRange = (weekRange) => {
  const [startStr, endStr] = weekRange.split(' to ');
  const startDate = new Date(startStr);
  const endDate = new Date(endStr);

  const startMonth = startDate.toLocaleString('default', { month: 'long' });
  const endMonth = endDate.toLocaleString('default', { month: 'long' });

  if (startMonth === endMonth) {
    return `${startMonth} ${startDate.getUTCDate()}-${endDate.getUTCDate()}, ${startDate.getUTCFullYear()}`;
  } else {
    return `${startMonth} ${startDate.getUTCDate()}, ${startDate.getUTCFullYear()} to ${endMonth} ${endDate.getUTCDate()}, ${endDate.getUTCFullYear()}`;
  }
};

const DeliveryList = () => {
  const [stores, setStores] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newDeliveryStore, setNewDeliveryStore] = useState('');
  const [newDeliveryDate, setNewDeliveryDate] = useState(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  });
  const [currentWeek, setCurrentWeek] = useState('');
  const [groupedDeliveries, setGroupedDeliveries] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true); // Start loading

    // This is sorted in the backend first date and then store name.
    api.get('/api/deliveries/')
      .then(response => {
        const sortedDeliveries = response.data.map(delivery => ({
          ...delivery,
          date: new Date(delivery.date) // Parse date to local date object
        }));
        
        const grouped = groupByWeek(sortedDeliveries);
        const today = new Date();
        const currentWeekKey = Object.keys(grouped).find(week => {
          const [start, end] = week.split(' to ').map(dateStr => new Date(dateStr));
          return today >= start && today <= end;
        });


        setGroupedDeliveries(grouped);
        setCurrentWeek(currentWeekKey || Object.keys(grouped)[0]); // Set the current week or default to the first week if not found
      })
      .catch(error => {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Response error:', error.response.data);
        } else if (error.request) {
          // No response was received
          console.error('Request error:', error.request);
        } else {
          // Something else caused an error
          console.error('Error', error.message);
        }
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  
    api.get('/api/stores/')
      .then(response => {
        setStores(response.data);
      })
      .catch(error => {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Response error:', error.response.data);
        } else if (error.request) {
          // No response was received
          console.error('Request error:', error.request);
        } else {
          // Something else caused an error
          console.error('Error', error.message);
        }
      });
  }, []);
  
  const getStoreName = (storeId) => {
    const store = stores.find(store => store.id === storeId);
    return store ? store.name : 'Unknown Store';
  };

  const handleCreateDelivery = async () => {
    if (!newDeliveryStore || !newDeliveryDate) {
        alert('Please select a store and a date.');
        return;
    }

    // Disable the button and show a loading indicator
    setIsCreating(true);
  
    try {
        const checkResponse = await api.get('/api/check_delivery/', {
          params: {
            store: newDeliveryStore,
            date: newDeliveryDate,
          },
        });
    
        if (checkResponse.data.exists) {
          alert('A delivery for this store and date already exists.');
          setIsCreating(false);  // Re-enable the button
          return;
        }

        // Step 1: Create delivery with store and date via the new endpoint
        const newDeliveryData = {
            store: newDeliveryStore,
            date: newDeliveryDate,
        };
  
        const createResponse = await api.post('/api/create_delivery_with_products/', newDeliveryData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
  
        const deliveryId = createResponse.data.id;
  
        if (createResponse.status === 201) {
            // Navigate only after successful creation
            navigate(`/edit-delivery/${deliveryId}`);
            setIsModalOpen(false);  // Close the modal after successful navigation
        } else {
            alert('Failed to create delivery. Please try again.');
        }
    } catch (error) {
        // Handle different types of errors
        if (error.response) {
            console.error('Server Error:', error.response.data);
            alert(`Error: ${error.response.data.message || 'Failed to create the delivery'}`);
        } else if (error.request) {
            console.error('Network Error:', error.request);
            alert('Network error. Please check your internet connection.');
        } else {
            console.error('Unexpected Error:', error.message);
            alert('An unexpected error occurred. Please try again.');
        }
    } finally {
        // Re-enable the button and hide the loading indicator
        setIsCreating(false);
    }
  };
  
  const handleDeleteDelivery = async (deliveryId) => {
    if (window.confirm('Are you sure you want to delete this delivery?')) {
      try {
        await api.delete(`/api/deliveries/${deliveryId}/`);
        setGroupedDeliveries((prevGroupedDeliveries) => {
          const newGroupedDeliveries = { ...prevGroupedDeliveries };
          Object.keys(newGroupedDeliveries).forEach((week) => {
            newGroupedDeliveries[week] = newGroupedDeliveries[week].filter(
              (delivery) => delivery.id !== deliveryId
            );
          });
          return newGroupedDeliveries;
        });
      } catch (error) {
        console.error('There was an error deleting the delivery!', error);
      }
    }
  };
  
  const handlePreviousWeek = () => {
    const weeks = Object.keys(groupedDeliveries);
    const currentIndex = weeks.indexOf(currentWeek);
    if (currentIndex < weeks.length - 1) {
      setCurrentWeek(weeks[currentIndex + 1]);
    }
  };

  const handleNextWeek = () => {
    const weeks = Object.keys(groupedDeliveries);
    const currentIndex = weeks.indexOf(currentWeek);
    if (currentIndex > 0) {
      setCurrentWeek(weeks[currentIndex - 1]);
    }
  };

  const currentDeliveries = groupedDeliveries[currentWeek] || [];

  return (
    <div className="wide-container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          {loading || !currentWeek ? 'Loading week data...' : `Deliveries for ${formatWeekRange(currentWeek)}`}
        </h2>
        <button 
          className="bg-blue-600 text-white px-4 py-2 rounded"
          onClick={() => setIsModalOpen(true)}
        >
          New Delivery
        </button>
      </div>
      <div className="flex justify-between items-center mb-4">
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded"
          onClick={handlePreviousWeek}
          disabled={!groupedDeliveries[currentWeek]}
        >
          Previous Week
        </button>
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded"
          onClick={handleNextWeek}
          disabled={!groupedDeliveries[currentWeek]}
        >
          Next Week
        </button>
      </div>
      <div className="bg-white shadow-md rounded p-4">
        {loading ? (
          <div className="flex justify-center items-center">
            <div className="inline-flex items-center">
              <div className="loader"></div>
              <p className="ml-2" style={{ lineHeight: '30px' }}>Loading deliveries...</p>
            </div>
          </div>
        ) : currentDeliveries.length === 0 ? (
          <p>No deliveries available for this week.</p>
        ) : (
          <ul className="space-y-4">
            {currentDeliveries.map(delivery => (
              <li key={delivery.id} className="border p-4 rounded flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <p className="font-bold">{delivery.id}</p>
                  <p className="font-bold">{getStoreName(delivery.store)}</p>
                  <p>{memoizedFormatDate(delivery.date)}</p>
                </div>
                <div className="ml-auto flex space-x-2">
                  <button 
                    className="bg-blue-600 text-white px-4 py-2 rounded mr-2"
                    onClick={() => navigate(`/edit-delivery/${delivery.id}`)}
                  >
                    Edit
                  </button>
                  <button 
                    className="bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => handleDeleteDelivery(delivery.id)}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      <Modal 
        isOpen={isModalOpen} 
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Create Delivery"
        className="bg-white rounded p-4 max-w-md mx-auto mt-20 shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-xl mb-4">New Delivery</h2>
        <div className="mb-4">
          <label className="block mb-2">Store:</label>
          <select
            value={newDeliveryStore}
            onChange={(e) => setNewDeliveryStore(e.target.value)}
            className="w-full border p-2 rounded"
          >
            <option value="">Select Store</option>
            {stores.map(store => (
              <option key={store.id} value={store.id}>{store.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Date:</label>
          <input
            type="date"
            value={newDeliveryDate}
            onChange={(e) => setNewDeliveryDate(e.target.value)}
            className="w-full border p-2 rounded"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button 
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button 
            className={`px-4 py-2 rounded ${isCreating ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 text-white'}`}
            onClick={handleCreateDelivery}
            disabled={isCreating}
          >
            {isCreating ? (
              <>
                <div className="loader"></div> {/* Add spinner element here */}
                <span className="ml-2">Creating...</span>
              </>
            ) : 'Create Delivery'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DeliveryList;
