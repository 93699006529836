import React, { useState, useEffect } from 'react';
import api from '../services/api';

const AssignProductPage = () => {
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [assignedProducts, setAssignedProducts] = useState([]);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    api.get('/api/stores/')
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the stores!', error);
      });

    api.get('/api/products/')
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the products!', error);
      });

    fetchAssignedProducts();
  }, []);

  const fetchAssignedProducts = () => {
    api.get('/api/product-assignments/')
      .then((response) => {
        setAssignedProducts(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the assigned products!', error);
      });
  };

  useEffect(() => {
    if (selectedStore) {
      setFilteredAssignments(assignedProducts.filter(ap => ap.store === parseInt(selectedStore)));
    } else {
      setFilteredAssignments([]);
    }
  }, [selectedStore, assignedProducts]);

  const handleAssignProduct = () => {
    api.post('/api/product-assignments/', {
      store: selectedStore,
      product: selectedProduct
    })
      .then((response) => {
        setMessage('Product assigned successfully!');
        fetchAssignedProducts();  // Refresh the list
        setSelectedProduct('');
      })
      .catch((error) => {
        console.error('There was an error assigning the product!', error);
        setMessage('There was an error assigning the product.');
      });
  };

  const handleRemoveAssignment = (assignmentId) => {
    api.delete(`/api/product-assignments/${assignmentId}/`)
      .then((response) => {
        setMessage('Product assignment removed successfully!');
        fetchAssignedProducts();  // Refresh the list
      })
      .catch((error) => {
        console.error('There was an error removing the product assignment!', error);
        setMessage('There was an error removing the product assignment.');
      });
  };

  const availableProducts = products.filter(product => 
    !filteredAssignments.some(assignment => assignment.product === product.id)
  );

  return (
    <div className="wide-container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Assign Product to Store</h2>
      <div className="mb-4">
        <label className="block mb-2">Store</label>
        <select
          onChange={(e) => setSelectedStore(e.target.value)}
          value={selectedStore}
          className="block w-full p-2 border border-gray-300 rounded"
        >
          <option value="">Select Store</option>
          {stores.map((store) => (
            <option key={store.id} value={store.id}>{store.name}</option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Product</label>
        <select
          onChange={(e) => setSelectedProduct(e.target.value)}
          value={selectedProduct}
          className="block w-full p-2 border border-gray-300 rounded"
        >
          <option value="">Select Product</option>
          {availableProducts.map((product) => (
            <option key={product.id} value={product.id}>{product.name}</option>
          ))}
        </select>
      </div>
      <button
        onClick={handleAssignProduct}
        className="bg-blue-600 text-white p-2 rounded"
      >
        Assign Product
      </button>
      <p className="mt-4">{message}</p>
      {selectedStore && (
        <>
          <h2 className="text-2xl font-bold mt-8 mb-4">Assigned Products</h2>
          <ul className="space-y-4">
            {filteredAssignments.map((assignment) => {
              const product = products.find(p => p.id === assignment.product);
              return (
                <li
                  key={assignment.id}
                  className="p-1 rounded flex justify-between items-center"
                  style={{ backgroundColor: product ? product.color : 'transparent' }}
                >
                  <span>
                    {product ? product.name : 'Unknown Product'}
                  </span>
                  <button
                    onClick={() => handleRemoveAssignment(assignment.id)}
                    className="bg-red-500 text-white p-2 rounded"
                  >
                    Remove
                  </button>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default AssignProductPage;
