// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import './Login.css';  // Import the CSS file for styling

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);  // Track loading state
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');  // Clear any existing errors
    setLoading(true);  // Start loading spinner

    try {
      await authService.login(username, password);
      onLogin();  // Call the onLogin function passed as prop
      navigate('/deliveries');  // Adjust the route to where you want to redirect after login
    } catch (err) {
      if (err.response) {
        // The request was made and the server responded with a status code
        if (err.response.status === 400) {
          setError('Incorrect username or password. Please try again.');
        } else if (err.response.status === 401) {
          setError('Unauthorized access. Please check your credentials and try again.');
        } else {
          setError('An error occurred. Please try again later.');
        }
      } else if (err.request) {
        // The request was made but no response was received
        setError('Network error. Please check your internet connection and try again.');
      } else {
        // Something happened in setting up the request that triggered an error
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);  // Stop loading spinner
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>Welcome to Sunrise Dairy Delivery Management System</h1>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              required
              disabled={loading}  // Disable input during loading
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
              disabled={loading}  // Disable input during loading
            />
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? <div className="loader"></div> : 'Login'}
          </button>
          {error && <p className="error-message" aria-live="polite">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
