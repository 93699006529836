import React, { useState, useEffect } from 'react';
import api from '../services/api';

function Stores() {
  const [stores, setStores] = useState([]);
  const [quickbooksIds, setQuickbooksIds] = useState([]); // For storing QuickBooks customer data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storeToEdit, setStoreToEdit] = useState(null); // Store selected for editing

  // Form states
  const [name, setName] = useState(''); // Form field for store name
  const [qbCustomerId, setQbCustomerId] = useState(''); // Form field for QB Customer ID

  // Fetch stores and QuickBooks customers when the component mounts
  useEffect(() => {
    // Fetch stores
    api.get('/api/stores/')
      .then((response) => {
        setStores(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('There was an error fetching the stores.');
        setLoading(false);
      });

    // Fetch QuickBooks Customer data from the import-customers endpoint
    api.get('/api/import-customers/')
      .then((response) => {
        const customers = response.data; // Assuming response is an array of customers directly
        console.log("Customers:", customers); // Log the customers to verify
        setQuickbooksIds(customers.map(customer => ({
          id: customer.id,
          displayName: customer.displayName
        })));
      })
      .catch((error) => {
        console.error('There was an error fetching the QuickBooks customer data.', error);
      });
  }, []);

  // Handle form submission (either for adding or editing a store)
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { name, qb_customer_id: qbCustomerId };

    if (storeToEdit) {
      // If we're editing an existing store
      api.put(`/api/stores/${storeToEdit.id}/`, payload)
        .then((response) => {
          setStores(stores.map(store => (store.id === storeToEdit.id ? response.data : store)));
          resetForm(); // Reset the form after updating
        })
        .catch((error) => {
          console.error('There was an error updating the store!', error);
        });
    } else {
      // If we're adding a new store
      api.post('/api/stores/', payload)
        .then((response) => {
          setStores([...stores, response.data]);
          resetForm(); // Reset the form after adding
        })
        .catch((error) => {
          console.error('There was an error adding the store!', error);
        });
    }
  };

  // Handle deleting a store
  const handleDelete = (storeId) => {
    if (window.confirm('Are you sure you want to delete this store?')) {
      api.delete(`/api/stores/${storeId}/`)
        .then(() => {
          setStores(stores.filter(store => store.id !== storeId));
        })
        .catch((error) => {
          console.error('There was an error deleting the store!', error);
        });
    }
  };

  // Reset form fields and exit edit mode
  const resetForm = () => {
    setName('');
    setQbCustomerId('');
    setStoreToEdit(null); // Exit edit mode
  };

  // Handle selecting a store for editing
  const handleEdit = (store) => {
    setStoreToEdit(store);
    setName(store.name);
    setQbCustomerId(store.qb_customer_id);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Cancel editing
  const handleCancelEdit = () => {
    resetForm();
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Stores</h2>

      {/* Form for adding or editing a store */}
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="mb-2">
          <label className="block text-sm font-bold mb-1">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border rounded w-full py-2 px-3"
            required
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm font-bold mb-1">QuickBooks Customer ID:</label>
          <select
            value={qbCustomerId}
            onChange={(e) => setQbCustomerId(e.target.value)}
            className="border rounded w-full py-2 px-3"
          >
            <option value="">Select QB Customer</option>
            {quickbooksIds.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.displayName}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
        >
          {storeToEdit ? 'Update Store' : 'Add Store'}
        </button>
        {storeToEdit && (
          <button
            type="button"
            onClick={handleCancelEdit}
            className="bg-gray-500 text-white py-2 px-4 rounded"
          >
            Cancel
          </button>
        )}
      </form>

      {/* List of stores */}
      <h3 className="text-xl font-bold mb-2">Existing Stores</h3>
      <ul className="list-disc pl-5">
        {stores.map((store) => (
          <li key={store.id} className="mb-2 flex justify-between items-center">
            <span className="font-semibold">{store.name}</span>
            <div>
              <button
                onClick={() => handleEdit(store)}
                className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 mr-2"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(store.id)}
                className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Stores;
