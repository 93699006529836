import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

function QuickBooks() {
    const [isQuickBooksConnected, setIsQuickBooksConnected] = useState(false); // To check QuickBooks connection
    const [loading, setLoading] = useState(true);
    const [disconnecting, setDisconnecting] = useState(false); // To handle disconnection loading state
    const [connecting, setConnecting] = useState(false); // To handle connection loading state
    const [message, setMessage] = useState(null); // To display success or error messages
    const [companyInfo, setCompanyInfo] = useState(null);
    const savedDeliveryId = sessionStorage.getItem('deliveryId');
    const savedInvoiceDate = sessionStorage.getItem('invoiceDate');
    const navigate = useNavigate();

    useEffect(() => {
        
        // Now check if the user is connected to QuickBooks
        api.get('/api/connected_quickbooks/')
            .then((qbResponse) => {
                if (qbResponse.data.logged_in) {
                    setIsQuickBooksConnected(true);
                    const companyInfo = qbResponse.data.company_info;

                    if (companyInfo) {
                        setCompanyInfo(companyInfo); // Store in state for display
                    }    

                    if (savedDeliveryId && savedInvoiceDate) {
                        // Resume creating the invoice after successful OAuth
                        navigate(`/edit-delivery/${savedDeliveryId}`);
                      }
                    // Clear the session storage after success
                    sessionStorage.removeItem('deliveryId');
                    sessionStorage.removeItem('invoiceDate');
                }
            })
            .catch((error) => {
                console.error('Error checking QuickBooks connection', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [navigate, savedDeliveryId, savedInvoiceDate]);

    const handleQuickBooksConnect = () => {
        setMessage(null);
        setConnecting(true);
        api.get('/api/oauth/')
          .then(response => {
            // Redirect to the QuickBooks authorization URL
            window.location.href = response.data.authorization_url;
          })
          .catch(error => {
            console.error('Error during QuickBooks OAuth initiation:', error);
            setMessage({ type: 'error', text: 'Failed to initiate QuickBooks connection.' });
          })
          .finally(() => {
                setConnecting(false); // End loading
            });
    };

    const handleQuickBooksDisconnect = () => {
        // Optional: Add a confirmation dialog
        const confirmDisconnect = window.confirm('Are you sure you want to disconnect your QuickBooks account?');
        if (!confirmDisconnect) return;

        setDisconnecting(true);
        setMessage(null); // Clear any existing messages

        api.post('/api/disconnect-quickbooks/')
            .then(response => {
                console.log(response.data);
                setIsQuickBooksConnected(false);
                setMessage({ type: 'success', text: 'Successfully disconnected from QuickBooks.' });
            })
            .catch(error => {
                console.error('Error disconnecting from QuickBooks:', error);
                // Extract error message from response if available
                const errorMsg = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Failed to disconnect from QuickBooks.';
                setMessage({ type: 'error', text: errorMsg });
            })
            .finally(() => {
                setDisconnecting(false);
            });
    };

      
    return (
        <div style={styles.container}>
            <h1>QuickBooks Integration</h1>

            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    {/* Display success or error messages */}
                    {message && (
                        <div
                            style={{
                                ...styles.message,
                                backgroundColor: message.type === 'success' ? '#d4edda' : '#f8d7da',
                                color: message.type === 'success' ? '#155724' : '#721c24',
                                borderColor: message.type === 'success' ? '#c3e6cb' : '#f5c6cb',
                            }}
                        >
                            {message.text}
                        </div>
                    )}

                    {/* Check if user is connected to QuickBooks */}
                    {isQuickBooksConnected ? (
                        <div>
                            <p style={styles.statusConnected}>You are connected to QuickBooks!</p>
                            <button
                                onClick={handleQuickBooksDisconnect}
                                style={{
                                    ...styles.button,
                                    backgroundColor: '#dc3545', // Bootstrap's danger color
                                    marginTop: '10px',
                                }}
                                disabled={disconnecting}
                            >
                                {disconnecting ? 'Disconnecting...' : 'Disconnect from QuickBooks'}
                            </button>
                        </div>
                    ) : (
                        <div>
                            <p style={styles.statusDisconnected}>You are not connected to QuickBooks.</p>
                            <button
                                onClick={handleQuickBooksConnect}
                                style={styles.button}
                                disabled={connecting}
                            >
                                {connecting ? 'Connecting...' : 'Connect to QuickBooks'}
                            </button>
                        </div>
                        
                    )}
                    {companyInfo && (
                        <div style={styles.companyInfoContainer}>
                            <p style={styles.companyInfoTitle}>Company Information</p>
                            <p><strong>Company Name:</strong> {companyInfo.company_name}</p>
                            <p><strong>Company ID:</strong> {companyInfo.company_id}</p>
                            <p><strong>Company Legal Name:</strong> {companyInfo.company_legal_name}</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

const styles = {
    container: {
        maxWidth: '600px',
        margin: '50px auto',
        padding: '20px',
        textAlign: 'center',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#0077B5', // LinkedIn-like blue
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    statusConnected: {
        color: '#28a745', // Bootstrap's success color
        fontWeight: 'bold',
    },
    statusDisconnected: {
        color: '#dc3545', // Bootstrap's danger color
        fontWeight: 'bold',
    },
    message: {
        padding: '10px',
        marginBottom: '15px',
        border: '1px solid',
        borderRadius: '4px',
    },
    companyInfoContainer: {
        backgroundColor: '#f1f1f1', // Light gray background to differentiate section
        padding: '15px',
        marginTop: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        textAlign: 'left',
    },
    companyInfoTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#0077B5', // Matching the button's blue color
    },
};

export default QuickBooks;
