// src/services/authService.js
import axios from 'axios';

const API_URL = 'https://books.sunrisedairytn.com/api/token/';

const getCSRFToken = () => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, 'csrftoken'.length + 1) === 'csrftoken=') {
        cookieValue = decodeURIComponent(cookie.substring('csrftoken'.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const login = async (username, password) => {
  const csrfToken = getCSRFToken();
  const response = await axios.post(API_URL, { username, password }, {
    headers: {
      'X-CSRFToken': csrfToken,
    },
  });
  if (response.data.access && response.data.refresh) {
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);
  }
  return response.data;
};

const refreshAccessToken = async () => {
  const refresh_token = localStorage.getItem('refresh_token');
  if (refresh_token) {
    try {
      const response = await axios.post(`${API_URL}refresh/`, { refresh: refresh_token });
      localStorage.setItem('access_token', response.data.access);
      return response.data.access;
    } catch (error) {
      console.error('Error refreshing token', error);
      logout();  // Optionally, logout the user if the refresh token is invalid
    }
  } else {
    logout();
  }
};

const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  window.location.href = '/login';  // Redirect to login page
};

const authService = {
  login,
  refreshAccessToken,
  logout,
};

export default authService;
